import React from "react"
import styled from "styled-components"
import { Row, Col } from "react-styled-flexboxgrid"
import { Primary, Alternate } from "../Section"

const WhiteSpace = styled.hr`
  margin: 8rem;
  background-color: transparent;
`

const Thanks = () => {
  return (
    <section>
      <Alternate centered backgroundHeight={350}>
        <h1>Message Sent!</h1>
      </Alternate>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <p>
              Thank you for your message! We will be contacting your shortly.
            </p>
            <WhiteSpace />
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default Thanks
